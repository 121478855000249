import * as React from "react"
import * as styles from "../styles/modules/Home.module.css"

const LaMiniGalerie = () => {
    return (
        <div className={styles.laMiniGalerie} style={{position: "absolute", right: 0, bottom: 0, fontSize: ".7rem"}}>
            <p style={{color: "grey", textAlign: "right", marginBlock: "0 2ch"}}>En résidence à :</p>
            <p style={{margin: 0}}><a href="http://laminigalerie.com/" className={styles.simpleLink} style={{fontWeight: 700}}>La MINI Galerie</a> - 23 Rue Fleuriau, La Rochelle</p>
        </div>
    )
}

export default LaMiniGalerie
