import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import * as styles from "../styles/modules/Home.module.css";
import SliderAside from "./SliderAside"
import { graphql, StaticQuery } from "gatsby"

const sideComponents = [
    <SliderAside color={"red"} type={"mini-galerie"} padded={false} />,
    <SliderAside color={"white"} type={"galerie-albane"} padded={true} />,
    <SliderAside color={"white"} type={"none"} padded={true} />,
    <></>
];

// const sideComponents = [
//   <div></div>,
//   <></>,
//   <></>,
//   <></>
// ];

class MainSlider extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            doRender: false,
        };

    }

    componentDidMount() {
        this.setState({ doRender: true });
    }

    render() {
        const { doRender } = this.state;
        console.log(doRender)


        

        const settings = {
            dots: false,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };


        if (doRender) {
            return (
                
                    <StaticQuery query={graphql`
        query SliderQuery {
            allWpMediaItem(
              filter: {title: {glob: "Mainslider__*"}}
              sort: {order: ASC, fields: title}
            ) {
              nodes {
                title
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 1080, maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                    gatsbyImageData
                  }
                }
              }
            }
          }`}
                        render={data => <Slider {...settings} className={styles.Slider}> { data.allWpMediaItem.nodes.map((imageData, index) => <SliderFrame key={imageData.title} data={imageData} sideComponent={sideComponents[index]}/>) }</Slider>}
                    />
                
            )

        }

        return null;

    }

}





const SliderFrame = ({ data, sideComponent }) => {
    const fitting = data.title.split("__")[2];
    const img = getImage(data.localFile)
    const altText = data.title
    return (
        <div className={styles.Frame} >
            <GatsbyImage transformOptions={{ fit: "contain" }} imgClassName={fitting == "fill" ? styles.imageFill : styles.imageNoFill} className={styles.FrameImageWrapper} image={img} alt={altText} />
            {sideComponent}
        </div>)
}



export default MainSlider;